import { AUTH0_PATH } from '@/components/layers/Auth0Layer/constants'

export { AUTH0_PATH }

export type PageHref = ValueOf<typeof PAGE>

export const PAGE = {
  INDEX: '/',
  REPORTS: '/reports',
  LOGIN: '/login', // will be added
  WAITING: '/waiting' as '/waiting',
}

export const PAGE_TITLE_MAP: Partial<Record<PageHref, string>> = {
  [PAGE.INDEX]: 'Situation Awareness',
  [PAGE.REPORTS]: 'Reports',
}

export const RETURN_URL_PARAM = 'returnUrl'

// Pages do not need authorization
export const PUBLIC_PAGES: PageHref[] = [PAGE.LOGIN]

export const getPageTitle = (page: PageHref) => PAGE_TITLE_MAP[page]

const isPageVisible = (pageUrl?: PageHref) => true

export const getPagesByUserPermissions = (): Omit<Record<PageHref, boolean>, '/login'> => ({
  [PAGE.INDEX]: isPageVisible(PAGE.INDEX),
  [PAGE.REPORTS]: isPageVisible(PAGE.REPORTS),
})
