'use client'

import React, { useState } from 'react'
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query'
import { queryCache } from './ReactQueryCache'

const QUERY_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
  queryCache,
}

export const ReactQueryProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [queryClient] = useState(() => new QueryClient(QUERY_CONFIG))

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
