import React from 'react'
import { Icon } from '@/components/common/Icon'
import { PAGE, getPageTitle } from '@/core/constants/pages'

export const pageNavItems = [
  {
    label: getPageTitle(PAGE.INDEX),
    icon: <Icon name="MapIcon" />,
    href: PAGE.INDEX,
  },
  {
    label: getPageTitle(PAGE.REPORTS),
    disabled: true,
    icon: <Icon name="ReportIcon" />,
    href: PAGE.REPORTS,
  },
]
