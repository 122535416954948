'use client'

import React from 'react'
import { Divider, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { Icon, IconProps } from '@/components/common/Icon'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import styles from './ProfileMenuButton.module.scss'

const getItems = ({
  logout,
}: {
  logout(): void
}): { title: string; iconName: IconProps['name']; divider?: boolean; onClick?(): void }[] => [
  { title: 'About', iconName: 'InfoIcon', divider: true },
  { title: 'Log Out', iconName: 'LogOutIcon', onClick: logout },
]

export const ProfileMenuButton: React.FC = () => {
  const { logout } = useNextAuth()
  const { user } = useCurrentUser()

  return (
    <Menu isLazy>
      <MenuButton as="div" className={styles.menuButton}>
        <div className="text-xs">{user.name}</div>
        <div>Mantis Analytics Company</div>
      </MenuButton>
      <MenuList className={styles.containerMenu} zIndex={11}>
        {getItems({ logout }).map((item) => (
          <React.Fragment key={item.title}>
            <MenuItem className={styles.menuItem} onClick={item.onClick}>
              <Icon name={item.iconName} />
              <span>{item.title}</span>
            </MenuItem>
            {item.divider && <Divider />}
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  )
}
