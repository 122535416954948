import React, { useState } from 'react'
import { Icon } from '@/components/common/Icon'
import { PageNavigatorPanel } from './PageNavigatorPanel'

export const NavigationMenuButton: React.FC<{ className: string }> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClick = () => setIsOpen((value) => !value)

  return (
    <>
      <Icon className={className} name="MenuIcon" onClick={onClick} />
      {isOpen && <PageNavigatorPanel closePanel={() => setIsOpen(false)} />}
    </>
  )
}
