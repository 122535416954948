import React from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import classNames from 'classnames'
import { getPagesByUserPermissions } from '@/core/constants/pages'
import styles from './PageNavigatorPanel.module.scss'
import { pageNavItems } from './pageNavItems'

interface PageNavigatorPanelProps {
  closePanel(): void
}

const getItems = () => {
  const pagePermissions = getPagesByUserPermissions()
  return pageNavItems.filter((item) => pagePermissions[item.href])
}

export const PageNavigatorPanel: React.FC<PageNavigatorPanelProps> = ({ closePanel }) => {
  const pathname = usePathname()
  const isActiveLink = (href: string) => href === pathname

  return (
    <>
      <div className={styles.container}>
        <div className={styles.menuItems}>
          {getItems().map((item) => (
            <Link
              key={item.href}
              className={classNames(styles.linkItem, {
                [styles.linkDisabled]: item.disabled,
                [styles.activeLink]: isActiveLink(item.href),
              })}
              href={item.disabled ? '#' : item.href}
              onClick={closePanel}
            >
              <div className={styles.linkStatus} />
              {item.icon}
              <span>{item.label}</span>
            </Link>
          ))}
        </div>
      </div>
      <div className={styles.backdrop} onClick={closePanel} />
    </>
  )
}
