import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/srv/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/srv/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/srv/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/srv/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/srv/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/srv/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/srv/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"preload\":true,\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/srv/src/components/common/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthLayout"] */ "/srv/src/components/layers/Auth0Layer/AuthLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChakraUILayer"] */ "/srv/src/components/layers/ChakraUILayer/ChakraUILayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/srv/src/components/layers/ReactQueryProvider/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/srv/src/components/layout/header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/srv/src/components/layout/PageContainer/PageContainer.module.scss");
;
import(/* webpackMode: "eager" */ "/srv/src/styles/globals.css");
