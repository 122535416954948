'use client'

import React, { useEffect } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { Loader } from '@/components/common/Loader'
import { PAGE, PUBLIC_PAGES, type PageHref, getPagesByUserPermissions } from '@/core/constants/pages'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import { getRedirectUrl } from '@/core/utils/url'
import { AUTH0_PATH } from './constants'

const isPublicPath = (path: PageHref | string) => PUBLIC_PAGES.includes(path as PageHref)

export const AuthLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const pathname = usePathname()
  const { isLoggedIn, status } = useNextAuth()
  const { user } = useCurrentUser()

  const authCheck = async (url: string) => {
    const path = url.split('?')[0]
    const notAuthorized = !isLoggedIn && !isPublicPath(path)
    const isWaitingPage = path === PAGE.WAITING

    if (status === 'loading') {
      return
    }

    if (notAuthorized) {
      router.push(AUTH0_PATH.LOGIN)
      return
    }

    if (isLoggedIn) {
      const isLoginPage = path === PAGE.LOGIN
      const isGuestUser = user.isGuestUser()

      // Logged user move to redirected url from "/login" form page
      if (isLoginPage) {
        const pagePermissions = getPagesByUserPermissions()
        const allowedPages = (Object.keys(pagePermissions) as PageHref[]).filter(
          (key) => !!(pagePermissions as any)[key],
        )
        const firstValidPath = allowedPages[0]

        const redirectUrl = getRedirectUrl(firstValidPath)
        const isRedirectUrlAllowed = !!allowedPages.filter((page) => redirectUrl?.startsWith(page))[0]

        let redirectPage = isRedirectUrlAllowed ? redirectUrl : firstValidPath

        if (isGuestUser) {
          redirectPage = PAGE.WAITING
        }

        router.push(redirectPage)
        return
      }

      // Move to "/waiting" page just registered user
      if (isGuestUser && !isLoginPage && !isWaitingPage) {
        router.push(PAGE.WAITING)
        return
      }
    }
  }

  useEffect(() => {
    authCheck(pathname || '')
  }, [isLoggedIn, status, pathname])

  if (!isLoggedIn && isPublicPath(pathname || '')) {
    return <>{children}</>
  }

  if (isLoggedIn) {
    return <>{children}</>
  }

  return <Loader size="xl" />
}
